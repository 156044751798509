<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      :destroyOnClose="true"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
        <!--        <a-button v-action:add type="primary" @click="handleAdd">增加运单</a-button>-->
        <!--        <SearchForm ref="SearchForm" :addressList="addressList" @handleSubmit="handleSeachSubmit"/>-->
      </div>
      <s-table
          ref="table"
          rowKey="fix_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:450}"
      >
        <div slot="address" slot-scope="text, item">
          <a>{{ item.startAddress.address_name }} / {{ item.endAddress.address_name }}</a>
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/message/fix'
import {STable} from "@/components";

export default {
  name: "FixListModel",
  components: {STable},
  data() {
    return {
      // 标题
      title: '',
      // 查询参数
      queryParam: {
        message_id: ''
      },
      // modal(对话框)是否可见
      visible: false,

      loading: false,

      isLoading: false,

      // 客户id
      message_id: "",

      columns: [
        // {
        //   title: '确认ID',
        //   dataIndex: 'fix_id',
        //   align: 'center',
        //   width: 60,
        // },
        {
          title: '确定人信息',
          dataIndex: 'personnel.personnel_name',
          align: 'center',
        },
        {
          title: '确定收到时间',
          dataIndex: 'create_time',
          align: 'center',
        },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   align: 'center',
        //   scopedSlots: {customRender: 'action'}
        // },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },

  methods: {
    /**
     * 显示弹窗
     */
    open({message_id, message_title}) {
      this.message_id = message_id
      this.title = message_title + ' - 确认列表'
      this.visible = true
    },

    /**
     * 关闭弹窗
     */
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    /**
     * 删除记录
     */
    handleDelete(item) {
      Api.deleted({cwId: item['cw_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },



    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
