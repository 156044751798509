<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <a-form-item label="接收人" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              button-style="solid"
              v-decorator="['receive_type', { initialValue: 0,rules: [{ required: true }] }]"
          >
            <a-radio-button :value="0">所有人</a-radio-button>
<!--            <a-radio-button :value="1">指定人员</a-radio-button>-->
          </a-radio-group>
        </a-form-item>
        <a-form-item label="选择接收人" :labelCol="labelCol" :wrapperCol="wrapperCol"
                     v-if="form.getFieldValue('receive_type')==1">
          <a-select
              allowClear
              v-decorator="['personnel_id', {rules: [{required: true, message: '请选择接收人'}]}]"
              placeholder="请选择接收人"
          >
            <a-select-option v-for="item in personnel_list" :value="item.personnel_id">
              {{ item.personnel_name }}（{{ item.personnel_type == 0 ? '司机' : '押运员' }}）
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="消息标题" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入消息标题"
              v-decorator="['message_title', {rules: [{required: true, message: '请输入消息标题'}]}]"
          />
        </a-form-item>
        <a-form-item label="消息内容" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea
              placeholder="请输入消息内容"
              v-decorator="['message_content', {rules: [{required: true, message: '请输入消息内容'}]}]"
              :auto-size="{ minRows: 5, maxRows: 10 }"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/message'
import _ from "lodash";
import * as personnelApi from "@/api/personnel";

export default {
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

      personnel_list: []
    }
  },
  mounted() {
    this.getPersonnelAll()
  },
  methods: {
    // 获取人员信息
    getPersonnelAll() {
      personnelApi.all().then(({data: {driver_list, supercargo_list}}) => {
        this.personnel_list = [...driver_list, ...supercargo_list]
      })
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '发布新消息'

      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑消息'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['receive_type', 'message_title', 'message_content'])
        setFieldsValue(data)
        this.$nextTick(() => {
          const data = _.pick(this.record, ['personnel_id'])
          setFieldsValue(data)
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({messageId: this.record['message_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
